@font-face {
  font-family: 'SourceHanSerifCN';
  src: url(./SourceHanSerifCN-ExtraLight.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url(./SourceHanSerifCN-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url(./SourceHanSerifCN-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url(./SourceHanSerifCN-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url(./SourceHanSerifCN-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url(./SourceHanSerifCN-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url(./SourceHanSerifCN-Heavy.ttf) format('truetype');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
