@font-face {
  font-family: 'DIN';
  src: url(./DIN-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'DIN';
  src: url(./DIN-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'DIN';
  src: url(./DIN-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'DIN';
  src: url(./DIN-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}